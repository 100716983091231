import { MAINTAIN_PERMISSION, FAS_CONFIGURATION } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Assign Facility",
      "search-by": "UserName,FullName",
      "ph-search-by": "Search by UserName, FullName",
      "has-active": false
    },
    scopedSlots: _vm._u([{
      key: "customFilter",
      fn: function() {
        return [_c("span", [_vm._v(" Number of user count: " + _vm._s(_vm.itemCount) + " ")])];
      },
      proxy: true
    }, {
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record;
        return [_vm.can(_vm.permissions.maintain) ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Assign")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "list__action-toggle-active mr-2",
          attrs: {
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "UserName",
    attrs: {
      "data-index": "userName",
      "title": "User Name"
    }
  }), _c("text-field", {
    key: "FullName",
    attrs: {
      "data-index": "fullName",
      "title": "Full Name"
    }
  }), _c("text-field", {
    key: "FacilityName",
    attrs: {
      "data-index": "facilityName",
      "title": "Facilities"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "UserManagement",
  inject: ["crud", "can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION
      }
    };
  },
  computed: {
    itemCount() {
      return this.crud.getPagination().count;
    }
  },
  methods: {}
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var UserManagement = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", {
    attrs: {
      "items": _vm.menuItems
    }
  }), _c("resource", {
    attrs: {
      "name": "fas.assetregisters.getlistusers",
      "api-url": _vm.apiUrl,
      "edit-route": "/assign-facility/:id",
      "page": _vm.page
    }
  }, [_c("user-management")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { UserManagement },
  data() {
    return {
      apiUrl,
      page: FAS_CONFIGURATION,
      menuItems: [
        {
          key: "configuration",
          title: "Configuration",
          path: ""
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
